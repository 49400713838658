var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stock-list-wrapper px-1"},[_vm._l((_vm.myStock.groups),function(group){return _c('div',{key:group.name,staticClass:"has-text-centered select-product-border",class:{
      'is-selected': group.isSelected,
      'is-disabled': _vm.isDisab,
      'pb-3': !group.isOpened
    }},[_c('div',{staticClass:"is-relative pt-2"},[_c('div',{on:{"click":function($event){return _vm.toggleList(group.name, _vm.openedGroups)}}},[_c('a',{staticClass:"tag-top-left has-text-weight-bold has-text-dark pt-1",class:{ 'is-disabled': _vm.isDisab }},[_c('b-icon',{attrs:{"icon":group.isOpened ? 'chevron-down' : 'chevron-up'}})],1),_c('a',{staticClass:"has-text-dark has-text-weight-bold",class:{ 'is-disabled': _vm.isDisab }},[_vm._v(" "+_vm._s(group.name)+" "),_c('b-tag',{attrs:{"type":"is-info is-light","rounded":""}},[(group.countSelec)?_c('span',[_vm._v(_vm._s(group.countSelec)+"/")]):_vm._e(),_vm._v(_vm._s(group.list.length))]),(group.batchSize && group.batchSize > 1)?_c('p',{staticClass:"is-size-7 is-italic has-text-primary"},[_vm._v(" Batch size: "+_vm._s(_vm._f("pluralize")(group.batchSize,"pc", { includeNumber: true }))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"tag-top-right pt-2"},[_c('b-checkbox',{attrs:{"type":"is-info is-light","native-value":group.name,"disabled":_vm.isDisab,"indeterminate":group.countSelec > 0 && group.countSelec < group.list.length,"size":"is-small"},on:{"input":function($event){return _vm.inputSelectGroup(group, $event)}},model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}})],1)]),_c('div',[(group.isOpened)?_c('div',{staticClass:"columns is-multiline is-mobile mb-0 pt-3 is-2 is-variable"},_vm._l((group.list),function(prod){return _c('div',{key:prod.id,staticClass:"column is-half py-0"},[_c('div',{staticClass:"select-product-border is-light-border has-text-centered",class:{
              'is-selected': prod.isSelected,
              'is-disabled': _vm.isDisab
            },on:{"click":function($event){return _vm.toggleList(prod.id, _vm.selectedProducts)}}},[_c('b',{staticClass:"has-text-success"},[_vm._v("#"+_vm._s(prod.rankid))]),_c(_vm.leanProductFigures[prod.type],{tag:"component",attrs:{"product":prod,"station":null,"show-guides":false,"show-finished":false,"is-disabled":_vm.isDisab,"is-template":true}})],1)])}),0):_vm._e()])])}),(_vm.kanbanSpaces)?_c('div',{staticClass:"select-product-border is-disabled has-background-light"},[_c('h4',{staticClass:"has-text-centered is-italic"},[_vm._v(" Kanban spaces available: ")]),_c('div',{staticClass:"columns is-multiline is-centered is-gapless"},_vm._l((_vm.kanbanSpaces),function(ks,ind){return _c('div',{key:ind,staticClass:"column is-6 py-0"},[_c('div',{staticClass:"kanban-space-empty has-text-centered title is-6"},[_vm._v(" "+_vm._s(ind + 1)+" ")])])}),0)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }