<template>
  <div class="move-stock-wrapper">
    <b-collapse aria-id="generalId" animation="slide" :open="isOpen">
      <div
        :slot="isDisab ? '' : 'trigger'"
        class="has-text-centered"
        slot-scope="props"
        aria-controls="generalId"
      >
        <b-button
          v-if="!isOpen"
          class="my-2"
          :disabled="isDisab"
          type="is-info is-light"
          :icon-right="props.open ? 'close' : 'chevron-up'"
          rounded
        >
          {{ props.open ? "Close" : "Move to another station" }}
        </b-button>
      </div>
      <div class="py-6" v-if="isChangeover">
        <p class="has-text-centered has-text-dark is-italic pb-1">
          Sending
          {{ finalQuantity | pluralize("unit", { includeNumber: true }) }} to
          {{ moveStation.name }}
        </p>
        <b-progress
          type="is-info"
          size="is-large"
          :value="changeoverAnim.pourc"
          :min="0"
          :max="100"
        ></b-progress>
      </div>
      <div v-if="products && products.length && !isChangeover">
        <span class="pt-0 my-0" v-if="finalQuantity">
          {{ isAll ? "Total" : "Selected" }}:
          <b>{{ finalQuantity | pluralize("pc", { includeNumber: true }) }}</b>
          - <b>{{ products[0].name }}</b>
          <!--
          <span v-if="moveStation">
            To: <b> {{ moveStation.name }}</b>
             <a
              class="has-text-info pl-1"
              v-if="!showUpdateStation"
              @click="showUpdateStation = true"
            >
              <b-icon size="is-small" icon="pencil"></b-icon>
            </a> 
          </span>-->
        </span>
        <b-field expanded v-if="showUpdateStation || !moveStation" grouped>
          <b-select
            placeholder="Select a Station"
            v-model="moveStation"
            expanded
          >
            <option
              v-for="option in allStations"
              :value="option"
              :key="option.id"
            >
              {{ option.name }}
              #{{ option.id }}
            </option>
          </b-select>
          <p class="control">
            <b-button type="is-info" @click="showUpdateStation = false"
              >OK</b-button
            >
          </p>
        </b-field>
        <p v-if="batchOrder.requireBatch">
          Batch size :
          <b>{{ currentBatchSize }}</b
          >.
          <em
            :class="{
              'has-text-success': batchOrder.isMultiple,
              'has-text-danger': !batchOrder.isMultiple
            }"
            v-if="!batchOrder.isMultiple && products.length < currentBatchSize"
          >
            You need {{ isAll ? "" : "to select" }}
            {{ currentBatchSize - products.length }} more pc{{
              currentBatchSize - products.length > 1 ? "s" : ""
            }}. </em
          ><em
            class="has-text-grey"
            v-if="!batchOrder.isMultiple && products.length > currentBatchSize"
          >
            This will move the first {{ finalQuantity }} units only ({{
              batchOrder.nbBatches - 1
            }}
            batch{{ batchOrder.nbBatches - 1 > 1 ? "es" : "" }}).
          </em>
        </p>
      </div>
      <div class="mt-2 buttons is-centered">
        <b-button
          :size="isOpen ? 'is-medium' : null"
          :disabled="
            !finalQuantity ||
              !moveStation ||
              isDisab ||
              isChangeover ||
              !products ||
              !products.length ||
              !checkEnoughSpaceNext.valid
          "
          rounded
          type="is-info"
          @click="moveStock(false)"
        >
          Move {{ finalQuantity }} unit{{ finalQuantity > 1 ? "s" : "" }}
        </b-button>
      </div>
      <div
        class="has-text-centered label mt-2 has-text-danger"
        v-if="
          products &&
            products.length &&
            moveStation &&
            !checkEnoughSpaceNext.valid
        "
      >
        Kanban: not enough space in <em>{{ moveStation.name }}</em>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { mapGetters } from "vuex";
import firebase from "@/firebaseConfig";

export default {
  name: "MoveStock",
  computed: {
    ...mapGetters(["currentGame", "movementDurations", "currentProducts"]),
    currentBatchSize() {
      return this.products && this.products.length
        ? this.currentGame.params.productsTemplates[
            this.products[0].template_id
          ].batchSize
        : 1;
    },
    keyChange() {
      return (
        "pid" +
        (this.products && this.products.length
          ? this.products[0].id + "pl" + this.products.length
          : "") +
        "fq" +
        this.finalQuantity +
        "checken" +
        this.checkEnoughSpaceNext.valid +
        "mauto" +
        this.faci.params.sendAuto
      );
    },
    allStations() {
      return Object.values(this.currentGame.facilities).filter(
        fac => fac.id !== this.faci.id
      );
    },
    products() {
      return this.allProducts.filter(
        prod => prod.template_id === this.allProducts[0].template_id
      );
    },
    batchOrder() {
      if (!this.currentBatchSize || this.currentBatchSize <= 1) {
        return {
          requireBatch: false,
          isMultiple: true
        };
      }
      let myQty = Math.min(
        this.checkEnoughSpaceNext.maxQty,
        this.products.length
      );
      let nbBatches =
        !this.products || this.products.length <= 0
          ? 0
          : Math.floor(myQty / this.currentBatchSize) + 1;
      return {
        requireBatch: true,
        isMultiple:
          myQty / this.currentBatchSize -
            Math.floor(myQty / this.currentBatchSize) ===
          0
            ? true
            : false,
        roundOrder: this.currentBatchSize * nbBatches,
        roundOrderMin: nbBatches ? this.currentBatchSize * (nbBatches - 1) : 0,
        nbBatches: nbBatches
      };
    },
    finalQuantity() {
      return this.batchOrder.isMultiple
        ? Math.min(this.checkEnoughSpaceNext.maxQty, this.products.length)
        : this.batchOrder.roundOrderMin;
    },
    checkEnoughSpaceNext() {
      let check = true;
      let countMax = this.products ? this.products.length : 0;
      if (this.moveStation && this.moveStation.params.kanbanCapa) {
        let countProd = this.currentProducts.filter(prod => {
          return (
            prod.pos.fid === this.moveStation.id &&
            prod.pos.zone === this.nextZone &&
            !prod.fulfilled_at
          );
        }).length;
        if (countProd + 1 > this.moveStation.params.kanbanCapa) {
          check = false;
          countMax = 0;
        } else {
          countMax = this.moveStation.params.kanbanCapa - countProd;
        }
      }
      return { valid: check, maxQty: countMax };
    }
  },
  props: {
    faci: Object,
    allProducts: Array,
    isAll: Boolean,
    isOpen: Boolean,
    isDisab: Boolean
  },
  created() {
    if (this.faci.prioNextStation) {
      this.moveStation = this.currentGame.facilities[this.faci.prioNextStation];
    }
  },
  methods: {
    moveStock(autoSend) {
      if (
        !this.products ||
        !this.products.length ||
        !this.finalQuantity ||
        !this.checkEnoughSpaceNext.valid ||
        !this.moveStation
      ) {
        return;
      }
      this.isChangeover = true;
      this.changeoverAnim.pourc = 0;
      anime({
        targets: this.changeoverAnim,
        pourc: 100,
        duration: this.movementDurations
          ? this.movementDurations[this.faci.id].to[this.moveStation.id]
          : 200,
        easing: "linear"
      }).finished.then(() => {
        this.isChangeover = false;
        this.changeoverAnim.pourc = 0;

        if (autoSend && !this.faci.params.sendAuto) {
          return;
        }
        this.$buefy.toast.open(
          `Moved ${this.finalQuantity} product${
            this.finalQuantity > 1 ? "s" : ""
          } to ${this.moveStation.name}`
        );
        this.$store.dispatch(
          "updateProductsDocs",
          this.products.slice(0, this.finalQuantity).map(prod => {
            let newMovesTab = [...prod.stats.moves];
            let timestamp = firebase.firestore.Timestamp.now();
            newMovesTab[newMovesTab.length - 1].leave_at = timestamp;
            // Add movement duration in the last move stats element :
            let prevDur = newMovesTab[newMovesTab.length - 1].move_duration
              ? newMovesTab[newMovesTab.length - 1].move_duration
              : 0;
            newMovesTab[newMovesTab.length - 1].move_duration =
              prevDur + this.movementDurations
                ? this.movementDurations[this.faci.id].to[this.moveStation.id]
                : 200;
            newMovesTab.push({
              fid: this.moveStation.id,
              zone: this.nextZone,
              enter_at: timestamp,
              actions: []
            });
            return {
              pid: prod.id,
              gid: this.currentGame.id,
              obj: {
                pos: { fid: this.moveStation.id, zone: this.nextZone },
                [`stats.moves`]: newMovesTab
              }
            };
          })
        );
        this.$emit("movedstock");
      });
    }
  },
  watch: {
    keyChange: {
      handler() {
        setTimeout(() => {
          if (this.faci.params.sendAuto) {
            this.moveStock(true);
          }
        }, 2);
      },
      immediate: true
    }
  },
  data() {
    return {
      nextZone: "wipin",
      showUpdateStation: false,
      moveStation: null,
      isChangeover: false,
      changeoverAnim: { pourc: 0 }
    };
  }
};
</script>

<style lang="scss">
.move-stock-wrapper {
}
</style>
