<template>
  <div class="stock-list-wrapper px-1">
    <div
      v-for="group in myStock.groups"
      :key="group.name"
      class="has-text-centered select-product-border"
      :class="{
        'is-selected': group.isSelected,
        'is-disabled': isDisab,
        'pb-3': !group.isOpened
      }"
    >
      <div class="is-relative pt-2">
        <div @click="toggleList(group.name, openedGroups)">
          <a
            class="tag-top-left has-text-weight-bold has-text-dark pt-1"
            :class="{ 'is-disabled': isDisab }"
          >
            <b-icon
              :icon="group.isOpened ? 'chevron-down' : 'chevron-up'"
            ></b-icon>
          </a>
          <a
            class="has-text-dark has-text-weight-bold"
            :class="{ 'is-disabled': isDisab }"
          >
            {{ group.name }}
            <b-tag type="is-info is-light" rounded
              ><span v-if="group.countSelec">{{ group.countSelec }}/</span
              >{{ group.list.length }}</b-tag
            >
            <p
              class="is-size-7 is-italic has-text-primary"
              v-if="group.batchSize && group.batchSize > 1"
            >
              Batch size:
              {{ group.batchSize | pluralize("pc", { includeNumber: true }) }}
            </p>
          </a>
        </div>

        <div class="tag-top-right pt-2">
          <b-checkbox
            type="is-info is-light"
            :native-value="group.name"
            :disabled="isDisab"
            v-model="selectedGroups"
            :indeterminate="
              group.countSelec > 0 && group.countSelec < group.list.length
            "
            size="is-small"
            @input="inputSelectGroup(group, $event)"
          ></b-checkbox>
        </div>
      </div>
      <div>
        <div
          v-if="group.isOpened"
          class="columns is-multiline is-mobile mb-0 pt-3 is-2 is-variable"
        >
          <div
            class="column is-half py-0"
            v-for="prod in group.list"
            :key="prod.id"
          >
            <div
              class="select-product-border is-light-border has-text-centered"
              @click="toggleList(prod.id, selectedProducts)"
              :class="{
                'is-selected': prod.isSelected,
                'is-disabled': isDisab
              }"
            >
              <b class="has-text-success">#{{ prod.rankid }}</b>
              <component
                v-bind:is="leanProductFigures[prod.type]"
                :product="prod"
                :station="null"
                :show-guides="false"
                :show-finished="false"
                :is-disabled="isDisab"
                :is-template="true"
              ></component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="kanbanSpaces"
      class="select-product-border is-disabled has-background-light"
    >
      <h4 class="has-text-centered is-italic">
        Kanban spaces available:
      </h4>
      <div class="columns is-multiline is-centered is-gapless">
        <div
          class="column is-6 py-0"
          v-for="(ks, ind) in kanbanSpaces"
          :key="ind"
        >
          <div class="kanban-space-empty has-text-centered title is-6">
            {{ ind + 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import anime from "animejs/lib/anime.es.js";
import { mapGetters } from "vuex";
// import tinycolor from "tinycolor2";

export default {
  name: "StockListElements",
  computed: {
    ...mapGetters([
      "loading",
      "currentUser",
      "currentGame",
      "currentProducts",
      "isCreator",
      "myFaci",
      "leanProductFigures"
    ]),
    kanbanSpaces() {
      let kObj = null;
      if (
        this.faci.params.kanbanCapa &&
        this.retObj.all.length < this.faci.params.kanbanCapa
      ) {
        kObj = new Array(this.faci.params.kanbanCapa - this.retObj.all.length);
      }
      return kObj;
    },
    myStock() {
      let groups = [];
      [...this.currentProducts]
        .sort((pa, pb) => {
          return (
            pa.stats.moves[pa.stats.moves.length - 1].enter_at.seconds -
            pb.stats.moves[pb.stats.moves.length - 1].enter_at.seconds
          );
        })
        .forEach(prod => {
          if (
            prod.pos.fid === this.faci.id &&
            prod.pos.zone === this.zone &&
            !prod.fulfilled_at
          ) {
            let findGroup = groups.find(gg => {
              return gg.name === prod.name;
            });
            let prodObj = {
              ...prod,
              isSelected: this.selectedProducts.some(pid => pid === prod.id)
            };
            if (findGroup) {
              findGroup.list.push(prodObj);
              findGroup.countSelec += prodObj.isSelected ? 1 : 0;
            } else {
              groups.push({
                name: prod.name,
                list: [prodObj],
                batchSize: this.currentGame.params.productsTemplates[
                  prod.template_id
                ].batchSize,
                isOpened: this.openedGroups.some(name => name === prod.name),
                isSelected: this.selectedGroups.some(
                  name => name === prod.name
                ),
                countSelec: prodObj.isSelected ? 1 : 0
              });
            }
          }
        });
      return {
        groups
      };
    },
    retObj() {
      return {
        all: [...this.currentProducts]
          .sort((pa, pb) => {
            return (
              pa.stats.moves[pa.stats.moves.length - 1].enter_at.seconds -
              pb.stats.moves[pb.stats.moves.length - 1].enter_at.seconds
            );
          })
          .filter(prod => {
            return (
              prod.pos.fid === this.faci.id &&
              prod.pos.zone === this.zone &&
              !prod.fulfilled_at
            );
          }),
        selected: this.currentProducts.filter(
          prod =>
            this.selectedProducts.find(sid => sid === prod.id) &&
            !prod.fulfilled_at
        )
      };
    }
  },
  props: { faci: Object, zone: String, value: Object, isDisab: Boolean },
  methods: {
    resetSelection() {
      this.selectedProducts = [];
    },
    toggleList(val, list) {
      if (this.isDisab) {
        return;
      }
      const index = list.indexOf(val);
      if (index > -1) {
        list.splice(index, 1);
      } else {
        list.push(val);
      }
    },
    inputSelectGroup(group, newList) {
      if (this.isDisab) {
        return;
      }
      const isSelected = newList.indexOf(group.name) > -1;
      group.list.forEach(prod => {
        const index = this.selectedProducts.indexOf(prod.id);
        // Add all products into selection
        if (index <= -1 && isSelected) {
          this.selectedProducts.push(prod.id);
        }
        // Remove all products from selection
        if (index > -1 && !isSelected) {
          this.selectedProducts.splice(index, 1);
        }
      });
    }
  },
  mounted() {
    //Open all groups at the begining :
    this.openedGroups = [];
    this.myStock.groups.forEach(group => {
      this.openedGroups.push(group.name);
    });
  },
  watch: {
    selectedProducts() {
      this.myStock.groups.forEach(group => {
        let checkAllSelec = true;
        group.list.forEach(prod => {
          if (!this.selectedProducts.some(pid => pid === prod.id)) {
            checkAllSelec = false;
          }
        });
        const index = this.selectedGroups.indexOf(group.name);
        if (checkAllSelec && index <= -1) {
          this.selectedGroups.push(group.name);
        }
        if (!checkAllSelec && index > -1) {
          this.selectedGroups.splice(index, 1);
        }
      });
    },
    retObj: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      openedGroups: [],
      selectedGroups: [],
      selectedProducts: [],
      productsFig: {
        dotsSheet: () =>
          import("@/components/lean/Play/Products/DotsSheetFigure.vue")
      }
    };
  }
};
</script>

<style lang="scss">
.stock-list-wrapper {
  a.is-disabled {
    cursor: not-allowed;
  }
  .kanban-space-empty {
    border: dotted 2px grey;
    padding: 1em;
    margin: 0.5em;
  }
}
</style>
